.stick_follow_icon {
    top: 50%;
    left: 30px;
    width: 20px;
    height: 200px;
    position: fixed;
    margin-top: -100px;
}

.stick_follow_icon ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.stick_follow_icon svg {
    width: 1.3em;
    height: 1.3em;
    fill: var(--text-color)
}

.stick_follow_icon p {
    top: 70px;
    left: -24px;
    width: 68px;
    height: 20px;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    white-space: nowrap;
    position: relative;
    transform: rotate( -90deg);
}

.stick_follow_icon ul li {
    display: block;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    transition: all .3s;
}

.stick_follow_icon p:after {
    top: 9px;
    right: -48px;
    width: 40px;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    background-color: var(--text-color);
}

@media only screen and (max-width: 760px) {
    .stick_follow_icon {
        display: none;
    }
}
/* 
@letme only screen and (max-width:){ .stick_follow_know what you wanna go with this and the we will go out through with the slayersf
@then when we come from this ristric we will be a good person when we come
somany time we are wrok ing together with the with u the when you come out of muy classification
}

.reputation_icon {  max-width: 350px; }
(concluatiino with this functional of couse when we come from the hell
i will go through with the dramatic twice and you can go with your dream
dream dream dramatic ::-webkit-search-decoration ) */